<template>
  <div id="init">
    <a-layout id="components-layout-demo-responsive">
      <!--  -->
      <div class="v-sider-wrap">
        <a-layout-sider class="v-sider show" v-model="collapsed" collapsible :trigger="null" @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave" width="250px">
          <div class="logo">
            <img src="@/assets/logo2.png" alt="">
          </div>
          <VNavbar></VNavbar>
        </a-layout-sider>
      </div>

      <a-layout class="v-layout" style="position: relative;">
        <div class="v-header-wrap">
          <VHeader>
            <a-icon slot="icon" type="menu-unfold" style="font-size: 18px; color: #99A1B7; margin-right: 10px;"
              class="showIcon" @click="showNavBar" />
          </VHeader>
          <div class="page-title">
            {{ $t('Navbar.' + getPageTitle) }}
          </div>
        </div>

        <Assets style="margin-top: 113px;"></Assets>
        <a-layout-content :style="{ background: 'transparent' }">
          <RouterView />
        </a-layout-content>

        <VFooter></VFooter>
      </a-layout>
    </a-layout>

    <a-drawer title="" placement="left" :closable="false" :visible="visible" @close="onClose" width="250px">
      <div class="logo">
        <img src="@/assets/logo2.png" alt="">
      </div>
      <VNavbar></VNavbar>
    </a-drawer>
  </div>
</template>

<script>
import VNavbar from './navbar.vue';
import VFooter from './footer.vue';
import VHeader from './header.vue';
import Assets from '@/components/assets.vue';
export default {
  components: {
    VNavbar,
    VFooter,
    VHeader,
    Assets
  },
  data() {
    return {
      collapsed: true,
      hide: window.innerWidth > 576 ? true : false,
      visible: false
    }
  },
  computed: {
    getPageTitle() {
      return this.$store.state.pageTitle
    }
  },
  methods: {
    onCollapse(collapsed, type) {
      // // console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      // // console.log(broken);
    },
    onMouseEnter() {
      this.collapsed = false;
    },
    onMouseLeave() {
      this.collapsed = true;
    },
    showNavBar() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
#init {
  box-sizing: border-box;
  width: 100%;
  min-height: calc(100vh - 30px);
}

.ant-layout {
  min-height: calc(100vh - 30px);
  background-color: transparent;
}

.ant-layout-sider {
  border-radius: 15px;
  background-color: var(--primary);
}

.logo {
  height: 75px;
  text-align: center;
  line-height: 75px;

  img {
    height: 25px;
  }
}

.page-title {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px 24px 8px 8px;
  color: var(--pageTitle);
  font-weight: 900;
  font-size: 18px;
}

.show {
  display: block;
}

.showIcon {
  display: none;
}

::v-deep .ant-drawer-body {
  padding: 15px 0;
}

::v-deep .ant-drawer-content {
  background: #006D5C;
}
::v-deep .ant-drawer-content-wrapper {
  border-radius: 0 15px 15px 0;
  overflow: hidden;
}

.v-layout {
  padding-left: 115px;
}

.v-sider-wrap {
  position: fixed;
  left: 15px;
  top: 15px;
  height: calc(100% - 30px);
  z-index: 10000;

  .v-sider {
    height: 100%;
  }
}

.v-header-wrap {
  width: calc(100vw - 160px);
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  padding-bottom: 15px;
}


@media screen and (max-width: 576px) {
  .show {
    display: none;
  }

  .showIcon {
    display: block;
  }

  .v-layout {
    padding-left: 0;
  }
  
  .v-header-wrap {
    width: 100%;
    padding: 15px 30px 0px 0;
    padding-bottom: 10px;
  }
}
</style>
