import service from "@/http/httpService";

// 获取国家列表
export const webUserGetCountryList = (params) => service.post("/api/country/list", { data: params, needEn: true });
// 注册
export const webUserRegister = (params) => service.post("/api/app/user/userRegister", { data: params, needEn: true });
// 登录
export const webUserLogin = (params) => service.post("/api/app/user/login", { data: params, needEn: true });
// APP 忘记密码
export const webGetUserForgotPassword = (params) => service.post("/api/app/user/forgotPassword", { data: params, needEn: true });
// 获取用户信息
export const webUserGetUserInfo = (params) => service.post("/api/app/user/userInfo", { data: params, needEn: true });
// App重置二级密码
export const webUserResetSecurityPassword = (params) => service.post("/api/app/user/updateTransactionPassword", { data: params, needEn: true });
// App重置密码
export const webUserResetPassword = (params) => service.post("/api/app/user/updatePassWord", { data: params, needEn: true });
// App重置邮箱
export const webUserResetEmail = (params) => service.post("/api/app/user/updateEmail", { data: params, needEn: true });
// // App Kyc
// export const webUserToKyc = (params) => service.post("/api/user-authentication-log/authentication", { data: params, needEn: true });
// // App 实名认证信息
// export const webUserQueryKycInfo = (params) => service.post("/api/user-authentication-log/lastAuthentication", { data: params, needEn: true });
// // App 实名认证信息
// export const webUserBindEmail = (params) => service.post("/api/app/wu-user/appSetUserEmail", { data: params, needEn: true });

// 检查二级密码
export const webGetUserCheckSecurityPassword = (params) => service.post("/api/app/wu-user/checkSecondaryPassword", { data: params, needEn: true });
// 设置二级密码
export const webGetUserSetSecurityPassword = (params) => service.post("/api/app/wu-user/addSecondaryPassword", { data: params, needEn: true });

// 获取等级
export const webUserQueryLevelList = (params) => service.post("/api/level-config/appGetLevelConfig", { data: params, needEn: true });

// App查询币种列表
export const webUserGetCoinList = (params) => service.post("/api/app/wu-coin/appCoinList", { data: params, needEn: true });

// App钱包列表
export const webUserGetAssetsList = (params) => service.post("/api/app/assets/assetsList", { data: params, needEn: true });
// App资产记录
export const webUserGetAssetsRecords = (params) => service.post("/api/app/assets/assetsRecordList", { data: params, needEn: true });
// App查询资产类型列表
export const webUserGetRecordType = (params) => service.post("/api/app/assetsRecord/type/subList", { data: params, needEn: true });

// App 钱包转换
export const webUserToConvert = (params) => service.post("/api/app/assets/assetsChange", { data: params, needEn: true });
// // App 兑换配置
// export const webUserGetConvertConfig = (params) => service.post("/api/app/wu-convert/appConvertConfigList", { data: params, needEn: true });
// // App 兑换记录
// export const webUserGetConvertRecord = (params) => service.post("/api/app/wu-convert/appConvertOrderList", { data: params, needEn: true });
// App 合约钱包锁仓时间
export const webUserGetConvertTime = (params) => service.post("/api/app/assets/assetsContractAccount", { data: params, needEn: true });

// App 转让
export const webUserToMakeOver = (params) => service.post("/api/app/wu-make-over/makeOver", { data: params, needEn: true });
// App 转让配置
export const webUserGetMakeOverConfig = (params) => service.post("/api/app/wu-make-over/appMakeOverConfigList", { data: params, needEn: true });
// App 转让记录
export const webUserGetMakeOverRecord = (params) => service.post("/api/app/wu-make-over/makeOverList", { data: params, needEn: true });

// App 提币
export const webUserToWithdrawal = (params) => service.post("/api/app/withdrawal/withdrawal", { data: params, needEn: true });
// App 提币配置
export const webUserGetWithdrawalConfig = (params) => service.post("/api/app/withdrawal/configList", { data: params, needEn: true });
// App 提币记录
export const webUserGetWithdrawalRecord = (params) => service.post("/api/app/withdrawal/withdrawalOrderList", { data: params, needEn: true });

// 获取首页轮播图
export const webUserGetBannerList = (params) => service.post("/api/app/wu-frontPage/carousel", { data: params, needEn: true });
// 获取分享二维码
export const webUserGetShareCode = (params) => service.post("/api/app/wu-user/invitation", { data: params, needEn: true });

// APP 币种类型
export const webUserGetMainCoinTypeList = (params) => service.post("/api/app/wu-deposit/appFindCoinTypeList", { data: params, needEn: true });

// 充值
export const webUserRecharge = (params) => service.post("/api/app/deposit/deposit", { data: params, needEn: true });
// 充值记录
export const webUserRechargeRecords = () => service.post("/api/app/wu-assets/assetsDepositRecordList", { data: {}, needEn: true });

// App查询邀请用户
export const webUserQueryInvitationUserList = (params) => service.post("/api/app/user/organization", { data: params, needEn: true });
// App查询收益
export const webUserQueryIncome = (params) => service.post("/api/app/user/income", { data: params, needEn: true });

// App查询配套配置
export const webUserQueryMatchingList = (params) => service.post("/api/app/wu-matching/appMatchingList", { data: params, needEn: true });
// // App查询当前配套
// export const webUserQueryCurrentMatching = params => service.post('/api/app/wu-matching/appQueryCurrentMatching', {data: params, needEn: true});
// App购买配套
export const webUserBuyMatching = (params) => service.post("/api/app/wu-matching/buyMatching", { data: params, needEn: true });
// App查询配套收益
export const webUserQueryMatchingProfit = (params) => service.post("/api/app/wu-matching/appBuyMatchingProfit", { data: params, needEn: true });
// App查询购买配套
export const webUserQueryMatchingRecords = (params) => service.post("/api/app/wu-matching/appBuyMatchingList", { data: params, needEn: true });

// 购买配套点击
export const webGetToClickMatching = (params) => service.post("/api/user-click-matching-log/clickMatching", { data: params, needEn: true });
// 配套点击记录
export const webGetClickMatchingRecords = (params) => service.post("/api/user-click-matching-log/clickMatchingLog", { data: params, needEn: true });

// 查询通知
export const webGetQueryNoticeList = (params) => service.post("/api/app/wu-notice/appNoticeList", { data: params, needEn: true });
// 查询媒体
export const webGetQueryMediaList = (params) => service.post("/api/app/wu-media/appMediaList", { data: params, needEn: true });

// 绑定钱包地址
export const webGetBindAddress = (params) => service.post("/api/app/wu-user/appUpdateWalletAddress", { data: params, needEn: true });

// 绑定谷歌验证
export const webGetBindGoogleAuth = (params) => service.post("/api/google-auth/bindGoogle", { data: params, needEn: true });
// 生成谷歌验证
export const webGetGenerateGoogleAuth = (params) => service.post("/api/google-auth/generateGoogleSecret", { data: params, needEn: true });
// 查询谷歌验证
export const webGetQueryGoogleAuth = (params) => service.post("/api/google-auth/getGoogleAuthByUser", { data: params, needEn: true });
// 终止配套
export const webCancelClickMatching = (params) => service.post("/api/user-click-matching-log/cancelClickMatching", { data: params, needEn: true });
// 配套点击收益明细
export const webAppMatchingClickIncome = (params) => service.post("/api/app/wu-assets/appMatchingClickIncome", { data: params, needEn: true });
// 充值列表
export const webAppDepositByMatching = (params) => service.post("/api/app/wu-deposit/appDepositByMatching", { data: params, needEn: true });
// 发送邮件
export const webSendEmailCode = (params) => service.post("/api/app/wu-user/sendEmailCode", { data: params, needEn: true });

// App领取奖励
export const webReceiveReward = (params) => service.post("/api/app/assets/receiveAssetsReward", { data: params, needEn: true });
// App 修改自动复投开关
export const updateContractStatus = (params) => service.post("/api/app/user/autoContractStatus", { data: params, needEn: true });

// App 获取return
export const getReturnWallet = (params) => service.post("/api/app/assets/returnAssets", { data: params, needEn: true });

// App查询配套
export const getMatchingList = (params) => service.post("/api/app/matching/matchingList", { data: params, needEn: true });
// App投资资产数量集合
export const getInvestListAmount = (params) => service.post("/api/app/assets/investAmountList", { data: params, needEn: true });


// App查询轮播图列表
export const getBannerList = (params) => service.post("/api/app/notice/appRotationNoticeList", { data: params, needEn: true });
// App查询公告列表
export const getNoticeList = (params) => service.post("/api/app/notice/appNoticeList", { data: params, needEn: true });


// App查询组织
export const webUserGetInviteTree = (params) => service.post("/api/app/user/organization", { data: params, needEn: true });

// App获取邀请链接
export const webUserGetInviteLink = (params) => service.post("/api/app/user/invitationLink", { data: params, needEn: true });

// 登录状态发送验证码
export const webUserGetCodeOnLogin = (params) => service.post("/api/app/user/loginSend", { data: params, needEn: true });
// 非登录状态发送验证码
export const webUserGetCodeOnNoLogin = (params) => service.post("/api/app/user/noLoginSend", { data: params, needEn: true });


// 获取撤资记录
export const webUserGetDevestRecords = (params) => service.post("api/app/assets/divestmentRecord", { data: params, needEn: true });

// 获取撤资记录
export const webUserGetServeTime = (params) => service.post("/api/app/user/serverTime", { data: params, needEn: true });


// App钱包转换
export const webUserAssetsChange = (params) => service.post("/api/app/assets/assetsChange", { data: params, needEn: true });
// App上下线钱包转换
export const webUserAssetsLineChange = (params) => service.post("/api/app/assets/userAssetsChange", { data: params, needEn: true });

// App查询活动列表
export const webUserQueryActivity = (params) => service.post("/api/app/activity/activityList", { data: params, needEn: true });
// 查询参与活动记录
export const webUserQueryActivityRecords = (params) => service.post("/api/app/activity/activityRecordList", { data: params, needEn: true });
// 参与活动
export const webUserJoinActivity = (params) => service.post("/api/app/activity/joinActivityRecord", { data: params, needEn: true });
// 取消参与活动
export const webUserCancelActivity = (params) => service.post("/api/app/activity/cancelActivity", { data: params, needEn: true });


// 检测绑定
export const webUserCheckBind = (params) => service.post("/api/app/bind/checkBind", { data: params, needEn: true });
// 绑定用户
export const webUserBindUser = (params) => service.post("/api/app/bind/rpcBind", { data: params, needEn: true });
// Rpc登录
export const webUserLoginBindUser = (params) => service.post("/api/app/bind/rpcLogin", { data: params, needEn: true });
// Rpc注册
export const webUserRegisterBindUser = (params) => service.post("/api/app/bind/rpcRegister", { data: params, needEn: true });


// 查询Lkt
export const webUserQueryLktInfo = (params) => service.post("/api/app/assets/capitalPoolInfo", { data: params, needEn: true });
// 购买Lkt
export const webUserBuyLkt = (params) => service.post("/api/app/assets/capitalPoolBuy", { data: params, needEn: true });
// 出售Lkt
export const webUserSellLkt = (params) => service.post("/api/app/assets/capitalPoolSell", { data: params, needEn: true });

// 手续费出售
export const getBankersFee = (params) => service.post("/api/app/assets/assetsChangeFeeInfo", { data: params, needEn: true });

// 修改用户双重验证状态
export const updateTwoCheckStatus = (params) => service.post("/api/app/user/updateTwoCheckStatus", { data: params, needEn: true });

// App游戏图表统计
export const getGameChartList = (params) => service.post("/api/app/assets/gameChartList", { data: params, needEn: true });
// AppLKT资金池图表统计
export const getLktChartList = (params) => service.post("/api/app/assets/capitalPoolChartList", { data: params, needEn: true });