import Vue from 'vue'
import Vuex from 'vuex'
import {
  webUserGetCoinList,
  webUserGetAssetsList,
  webUserGetUserInfo,
  webUserQueryLevelList,
  webUserGetMainCoinTypeList,
  webGetQueryNoticeList,
  webGetQueryMediaList,
  getReturnWallet
} from "@/api/index" // 接口

Vue.use(Vuex);

let lang = localStorage.getItem('lang') || 'zh-CN';

export default new Vuex.Store({
  state: {
    loading: false,
    lang: lang,
    AssetsList: [],
    UserInfo: {},
    pageTitle: '主页'
  },
  getters: {
    twoStepStatus(state) {
      return state.UserInfo.twoCheckStatus;
    }
  },
  mutations: {
    changeLoadingState(state, val) {
      state.loading = val;
    },
    SET_StoreMutationUserInfo(state, item) {
      state.UserInfo = item;
    },
    SET_StoreMutationAssetsList(state, assets) {
      state.AssetsList = assets;
    },
    SET_StorePageTitle(state, val) {
      state.pageTitle = val;
    }
  },
  actions: {
    async storeActionUserInfo(context) {  // 获取用户信息
      const { data } = await webUserGetUserInfo({});
      if (data.code === 20000) {
        // console.log('UserInfo :>> ', data.data);
        context.commit('SET_StoreMutationUserInfo', data.data);
      }
    },
    async storeActionAssetsList(context) {  // 获取钱包列表
      const { data } = await webUserGetAssetsList({});
      if (data.code === 20000) {
        let records = data.data.records;
        // console.log('Assets :>> ', records);
        context.commit('SET_StoreMutationAssetsList', records)
      }
    }
  },
  modules: {
    
  }
})
