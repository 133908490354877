<template>
  <div class="assets">
    <div :gutter="15" class="row">
      <div class="col" v-for="(item, index) in list" :key="index">
        <div class="assets-item">
          <div class="assets-inner flex align-center justify-between">
            <div :style="{ background: item.bgColor }" class="icon flex align-center justify-center">
              <img v-if="item.img" class="assets-img" :src="item.img" alt=""
                :style="{ height: item.type === 1 ? '40px' : '25px' }">
              <a-icon v-else :type="item.icon" style="font-size: 20px;" />
            </div>
            <div class="right flex flex-column align-end">
              <div class="name">{{ $t('coin.' + item.coinName) }}</div>
              <div class="available">
                {{ getAssetObj[item.coinName] || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { img: require('../assets/usdtlogo.png'), bgColor: '#26a17b', coinName: 'USDT_POINT', type: 1 },
        { icon: 'wallet', bgColor: '#ff8379', coinName: 'BankersWallet' },
        { img: require('../assets/usdtlogo.png'), bgColor: '#26a17b', coinName: 'CommunityWalletUsdt', type: 1 },
        { img: require('../assets/logo2.png'), bgColor: '#f33b42', coinName: 'LKT_POINT' },
        { icon: 'wallet', bgColor: '#ff8379', coinName: 'BankerBonusPOINT' },
      ]
    }
  },
  computed: {
    getAssetObj() {
      let obj = {};
      let AssetsList = this.$store.state.AssetsList;

      if (AssetsList.length > 0) {
        AssetsList.forEach(item => {
          obj[item.coinName] = item.assetsAvailable.toFixed(2);
        })
      }
      return obj
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.assets {
  padding: 15px 0;
}

.assets-item {
  background: rgb(17, 135, 116);
  background: linear-gradient(72deg, rgba(17, 135, 116, 1) 11%, rgba(1, 83, 70, 1) 90%);
  border-radius: 5px;
  overflow: hidden;

  .assets-inner {
    padding: 20px;
    width: 100%;
    height: 100%;
    background: #fff;
    background: url(../assets/cardbg.png) 100%;
  }

  .assets-img {
    height: 25px;
  }

  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .name {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  .available {
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    color: #fff;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  .col {
    flex: 1;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .assets-item {
    margin-bottom: 10px;

    .assets-inner {
      padding: 10px 15px;
      align-items: center;
      flex-direction: column;
    }

    .assets-img {
      height: 15px;
    }

    .icon {
      width: 40px;
      height: 40px;
    }

    .right {
      align-items: center;
    }

    .name {
      font-size: 10px;
    }

    .available {
      line-height: 30px;
      font-size: 22px;
    }
  }

  

}

@media screen and (max-width: 768px) {
  .row {
    .col {
      flex: 0 1 50%;
      padding: 0 7.5px;
      margin: 0;

      &:nth-child(2n - 1) {
        padding-left: 0;
      }

      &:nth-child(2n) {
        padding-right: 0;
      }
    }
  }
}
</style>