import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/init.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home.vue'),
        meta: { title: 'Home' }
      },
      {
        path: '/notice',
        name: 'Notice',
        component: () => import('@/views/notice/index.vue'),
        meta: { title: 'Notice' }
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('@/views/activity/index.vue'),
        meta: { title: 'Activity' }
      },
      {
        path: '/activity-records',
        name: 'ActivityRecords',
        component: () => import('@/views/activity/records.vue'),
        meta: { title: 'Activity' }
      },
      {
        path: '/deposit',
        name: 'Deposit',
        component: () => import('@/views/deposit/index.vue'),
        meta: { title: 'Deposit' }
      },
      {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import('@/views/withdraw/index.vue'),
        meta: { title: 'Withdraw' }
      },
      {
        path: '/transfer',
        name: 'Transfer',
        component: () => import('@/views/transfer/index.vue'),
        meta: { title: 'Transfer' }
      },
      {
        path: '/convert',
        name: 'Convert',
        component: () => import('@/views/convert/index.vue'),
        meta: { title: 'Convert' }
      },
      {
        path: '/assets-records',
        name: 'AssetsRecords',
        component: () => import('@/views/assets/records.vue'),
        meta: { title: 'Assets' }
      },
      {
        path: '/city',
        name: 'City',
        component: () => import('@/views/lktCity/index.vue'),
        meta: { title: 'City' }
      },
      {
        path: '/lkt',
        name: 'Lkt',
        component: () => import('@/views/lkt/index.vue'),
        meta: { title: 'Lkt' }
      },
      {
        path: '/invite',
        name: 'Invite',
        component: () => import('@/views/invite/index.vue'),
        meta: { title: 'Invite' }
      },
      {
        path: '/tree',
        name: 'Tree',
        component: () => import('@/views/inviteTree/index.vue'),
        meta: { title: 'Tree' }
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('@/views/account/index.vue'),
        meta: { title: 'Account' }
      },
      {
        path: '/policy',
        name: 'Policy',
        component: () => import('@/views/policy/index.vue'),
        meta: { title: 'Policy' }
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@/views/help/index.vue'),
        meta: { title: 'Help' }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/user/register.vue'),
    meta: { title: 'Register' }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/user/forgot.vue'),
    meta: { title: 'Forgot' }
  }
]

// 解决报错 NAVIGATION.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'hash'
})


// 不需要登录页面 name
const LOGIN_PAGE_NAME = ['Login', 'Register', '404', 'Forgot'];

// 跳转之前
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    if (LOGIN_PAGE_NAME.includes(to.name)) {
      next();
    } else {
      next('/login');
    }
  }
})
export default router
