import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'

Vue.use(Antd);

Vue.config.productionTip = false;

document.title = 'Luckard';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
