import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

// 方式二
import zh from "./zh/index";
import en from "./en/index";
import th from "./th/index";

Vue.use(VueI18n);

/**
 * 方式二
 * https://segmentfault.com/a/1190000020297356?utm_source=tag-newest TS中使用
 * {{ $t('m.message') }}
 */
let messages = {
  en: {
    ...en,
  },
  zh: {
    ...zh,
  },
  th: {
    ...th,
  },
};

let getLocaleI18n = localStorage.getItem("lang") || "zh-CN";
let localeI18n = "";

// // console.log(getLocaleI18n);
switch (getLocaleI18n) {
  case "en-US":
    localeI18n = "en";
    break;
  case "zh-CN":
    localeI18n = "zh";
    break;
  case "th-TH":
    localeI18n = "th";
    break;
}

const i18n = new VueI18n({
  locale: localeI18n == "" ? "zh" : localeI18n,
  messages,
});

export default i18n;
