<template>
  <div id="app">
    <a-spin :spinning="getLoading">
      <a-config-provider :locale="localData[locale]">
        <router-view />
      </a-config-provider>
    </a-spin>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/lib/locale-provider/en_US';
export default {
  data() {
    return {
      locale: localStorage.getItem('lang') || 'zh-CN',
      localData: {
        'zh-CN': zhCN,
        'en-US': enUS
      }
    }
  },
  computed: {
    getLoading() {
      return this.$store.state.loading
    }
  },
  created() {
    // // console.log('localData[locale] :>> ', this.localData[this.locale]);
    const token = localStorage.getItem('token');
    if (token) {
      this.$store.dispatch('storeActionUserInfo');
      this.$store.dispatch('storeActionAssetsList');
    }
  }
}
</script>

<style>
@import url(./common/common.scss);

html {
  --primary: #006D5C;
  --navbar: #00e8c6;
  --border: #78829D;
  --headerDropHover: #0ba637;
  --pageTitle: #071437;
  --cardBorder: #DBDFE9;
  --success: #17C653;
  --labelColor: #78829D;
  --gray: #99A1B7;
  --tip: #99A1B7;
}

#app {
  box-sizing: border-box;
  padding: 15px 30px 15px 15px;
  width: 100vw;
  height: 100vh;
  background: url(./assets/bg.jpg);
  color: #f1f1f1;
  overflow: auto;

}

div {
  box-sizing: border-box;
}

.ant-card {
  border-radius: 5px !important;
  margin-bottom: 10px;
}

.pagination {
  padding: 15px 0;
  text-align: right;
}

.ant-input {
  width: 100%;
}

.ant-card {
  margin-bottom: 15px !important;
}

.ant-card-head-title {
  height: 55px;
  padding: 0 !important;
  font-weight: bold !important;
  display: flex !important;
  align-items: center;
}

@media screen and (max-width: 576px) {
  #app {
    padding: 15px 10px 80px 15px;
  }

  .ant-card-head-title {
    height: auto;
    padding: 10px 0 !important;
    line-height: 40px;
  }
}
</style>
