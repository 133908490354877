import axios from './axios'

const services = {
  request(params) {
    try {
      return axios(params).catch(err=> {
        // // console.log('err :>> ', err);
      });
    } catch (err) {
      // console.log('err :>> ', err);
    }
  },

  /**
   * get
   * @param [url] 地址
   * @param [data] 数据
   * @returns Promise
   */
  get(reqUrl, reqData) {
    return this.request({
      method: 'GET',
      url: reqUrl,
      params: reqData,
    });
  },

  /**
   * put
   * @param [url] 地址
   * @param [data] 数据
   * @returns Promise
   */
  put(reqUrl, reqData) {
    return this.request({
      method: 'PUT',
      url: reqUrl,
      data: reqData,
    });
  },

  /**
   * post
   * @param [url] 地址
   * @param [data] 数据
   * @returns Promise
   */
  post(reqUrl, reqData) {
    return this.request({
      method: 'POST',
      url: reqUrl,
      data: reqData,
    });
  },

  /**
   * delete
   * @param [url] 地址
   * @param [data] 数据
   * @returns Promise
   */
  delete(req) {
    return this.request({
      method: 'DELETE',
      url: req.url,
      params: req.data,
    });
  }
}

// 封装HTTP工具类
export default services