<template>
  <div class="navbar">
    <a-menu mode="inline" :default-selected-keys="[defaultKey]">
      <template v-for="(item, index) in navList">
        <a-menu-item :key="index" v-if="!item.subMenu" @click="onClickMenu(item)">
          <div>
            <a-icon :type="item.icon" />
            <span class="nav-text">{{ $t('Navbar.' + item.label) }}</span>
          </div>
        </a-menu-item>
        <a-sub-menu :key="index + ''" v-else>
          <span slot="title">
            <a-icon :type="item.icon" />
            <span>{{ $t('Navbar.' + item.label) }}</span>
          </span>
          <a-menu-item :key="index + '-' + subInd" v-for="(sub, subInd) in item.subMenu" @click="onClickMenu(sub)">
            <div class="flex align-center">
              <span class="dot"></span>
              {{ $t('Navbar.' + sub.label) }}
            </div>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { label: '主页', icon: 'home', path: '/' },
        { label: '通告', icon: 'container', path: '/notice' },
        {
          label: '活动', icon: 'project',
          subMenu: [
            { label: '参与活动', path: '/activity' },
            { label: '过往活动记录', path: '/activity-records' },
          ]
        },
        {
          label: '理财', icon: 'wallet',
          subMenu: [
            { label: '充值', path: '/deposit' },
            { label: '提款', path: '/withdraw' },
            { label: '转换', path: '/convert' },
            { label: '转账', path: '/transfer' },
          ]
        },
        { label: '交易记录', icon: 'container', path: '/assets-records' },
        { label: '拉克娱乐城', icon: 'global', path: '/city' },
        { label: 'LKT保障池', icon: 'dollar', path: '/lkt' },
        {
          label: '联盟计划', icon: 'team',
          subMenu: [
            { label: '推荐链接', path: '/invite' },
            { label: '推荐用户', path: '/tree' },
          ]
        },
      ],
      defaultKey: 0
    }
  },
  methods: {
    onClickMenu({ label, path }) {
      this.$store.commit('SET_StorePageTitle', label);
      this.$router.push(path);
    }
  },
  created() {
    let path = this.$route.path;
    this.navList.forEach((item, index) => {
      if (item.path) {
        if (path === item.path) {
          this.defaultKey = index;
          this.$store.commit('SET_StorePageTitle', item.label);
        }
      } else {
        item.subMenu.forEach((sub, subInd) => {
          if (sub.path === path) {
            this.$store.commit('SET_StorePageTitle', sub.label);
            this.defaultKey = index + '-' + subInd;
          }
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-menu {
  background: transparent;
  border: 0;
}

::v-deep .ant-menu-item {
  background-color: transparent !important;
  color: var(--navbar) !important;
  font-weight: bold;

  &:hover {
    font-weight: bold;
    color: #fff;
  }

  &::after {
    border-right: 0 !important;
  }
}

::v-deep .ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0 20px !important;
}

::v-deep .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0 20px !important;
}

::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #004d41 !important;
  color: #fff !important;
  border-radius: 5px;

  .anticon {
    color: var(--navbar);
  }

  &::after {
    border-right: 0;
  }
}

::v-deep .ant-menu-submenu-selected {
  color: #fff !important;
}


::v-deep .ant-menu-sub {
  background: transparent !important;
  border: 0;
}

::v-deep .ant-menu-submenu-title:hover {
  color: #fff !important;

  .ant-menu-submenu-arrow {
    &::after {
      background: #fff !important;
    }

    &::before {
      background: #fff !important;
    }
  }
}

::v-deep .ant-menu-submenu-title {
  .ant-menu-submenu-arrow {
    &::after {
      background: var(--navbar) !important;
    }

    &::before {
      background: var(--navbar) !important;
    }
  }
}


.ant-menu-submenu {
  color: var(--navbar);
  font-weight: bold;

  &:hover {
    font-weight: bold;
    color: #fff;
  }

  &::after {
    border-right: 0 !important;
  }

  .ant-menu-submenu-title {
    color: var(--navbar);
    font-weight: bold;

    &:hover {
      font-weight: bold !important;
      color: #fff !important;
    }

    &::after {
      border-right: 0 !important;
    }
  }
}



.navbar {
  overflow: hidden;
  padding: 10px;
  background-color: var(--primary);
}

.dot {
  margin-right: 10px;
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: var(--navbar);
  border-radius: 50%;
}

@media screen and (max-width: 576px) {

  .ant-menu-inline .ant-menu-item {
    font-size: 12px !important;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 12px !important;
  }
}
</style>