/**
 *  IP 信息
 */
let baseUrl, imageUrl;

// const devUrl = 'http://217.79.252.98:21320';
const devUrl = 'https://member.luckard.vip';

switch (process.env.NODE_ENV) {
  case "development":

    baseUrl = devUrl + "/luckard";
    imageUrl = devUrl + "/luckard/";

    break;
  case "production":

    baseUrl = "https://member.luckard.vip/luckard";
    imageUrl = "https://member.luckard.vip/luckard/";

    break;
}

export { baseUrl, imageUrl };
