import Common from './Common/index.json';
import convert from './convert/index.json';
import index from './index/index.json';
import recharge from './recharge/index.json';
import report from './report/index.json';
import transfer from './transfer/index.json';
import userLogin from './userLogin/index.json';
import wallet from './wallet/index.json';
import withdraw from './withdraw/index.json';
import coin from './coin/index.json';
import level from './level/index.json';
import type from './type/index.json';
import security from './security/index.json';
import Navbar from './navbar.json';

export default {
  Common: {
    ...Common,
  },
  convert: {
    ...convert,
  },
  userLogin: {
    ...userLogin,
  },
  withdraw: {
    ...withdraw,
  },
  index: {
    ...index,
  },
  recharge: {
    ...recharge,
  },
  report: {
    ...report,
  },
  transfer: {
    ...transfer,
  },
  wallet: {
    ...wallet,
  },
  coin: {
    ...coin,
  },
  level: {
    ...level,
  },
  type: {
    ...type,
  },
  security: {
    ...security,
  },
  Navbar: {
    ...Navbar,
  },
}