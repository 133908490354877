<template>
  <div class="header">
    <a-layout-header :style="{ background: '#fff', padding: 0 }">

      <div class="header-inner  flex align-center justify-between">

        <div class="logo-green flex align-center">
          <slot name="icon"></slot>
          <img src="@/assets/logo_color.png" alt="">
        </div>

        <div>
          <a-dropdown :trigger="['hover', 'click']">
            <div class="right-dropdown">
              <img src="@/assets/avatar.jpg" alt="">
              <span class="name">{{ getUserName }}</span>
            </div>
            <a-menu slot="overlay">
              <a-menu-item>
                <div class="dropUser flex">
                  <div class="level" v-if="getUserLevel">
                    <img :src="require(`@/assets/ranking/${getUserLevel}.png`)" alt="">
                  </div>
                  <div class="name">
                    <div>
                      {{ getUserName }}
                    </div>
                    <div style="font-weight: normal;">{{ getLevelName }}</div>
                  </div>
                </div>
              </a-menu-item>
              <a-menu-item>
                <a class="menu-item" href="javascript:;" @click="$router.push('/policy')">{{ $t('Navbar.条款和政策') }}</a>
              </a-menu-item>
              <a-menu-item>
                <a class="menu-item" href="javascript:;" @click="$router.push('/help')">{{ $t('Common.自助指南') }}</a>
              </a-menu-item>
              <a-menu-item>
                <a class="menu-item" href="javascript:;" @click="$router.push('/account')">{{ $t('Navbar.账号管理') }}</a>
              </a-menu-item>

              <a-sub-menu key="lang">
                <template slot="title">
                  <a class="menu-item flex justify-between align-center" href="javascript:;">
                    <div>{{ $t("Navbar.语言") }}</div>
                    <span class="flex align-center">
                      {{ langObj[lang] }}
                      <img class="flag" src="@/assets/china.svg" alt="" srcset="" v-if="lang === 'zh-CN'">
                      <img class="flag" src="@/assets/united-states.svg" alt="" srcset="" v-else-if="lang === 'en-US'">
                      <img class="flag" src="@/assets/thai.png" alt="" srcset="" v-else>
                    </span>
                  </a>
                </template>
                <a-menu-item>
                  <a class="menu-item flex justify-between align-center" href="javascript:;"
                    @click="switchLang('zh-CN')">
                    <span class="flex align-center">
                      <img class="flag" style="margin-right: 10px;" src="@/assets/china.svg" alt="" srcset="">
                      简体中文
                    </span>
                  </a>
                </a-menu-item>
                <a-menu-item>
                  <a class="menu-item flex justify-between align-center" href="javascript:;"
                    @click="switchLang('en-US')">
                    <span class="flex align-center">
                      <img class="flag" style="margin-right: 10px;" src="@/assets/united-states.svg" alt="" srcset="">
                      English
                    </span>
                  </a>
                </a-menu-item>
                <a-menu-item>
                  <a class="menu-item flex justify-between align-center" href="javascript:;"
                    @click="switchLang('th-TH')">
                    <span class="flex align-center">
                      <img class="flag" style="margin-right: 10px;" src="@/assets/thai.png" alt="" srcset="">
                      แบบไทย
                    </span>
                  </a>
                </a-menu-item>
              </a-sub-menu>
              <!-- <a-menu-item>

              </a-menu-item>
              <a-menu-item>

              </a-menu-item> -->
              <a-menu-item>
                <a class="menu-item" @click="onLogout">{{ $t('Navbar.退出') }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </a-layout-header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh-CN',
      langObj: {
        'zh-CN': '简体中文',
        'en-US': 'English',
        'th-TH': 'แบบไทย',
      }
    }
  },
  computed: {
    getUserInfo() {
      return this.$store.state.UserInfo;
    },
    getUserName() {
      return this.getUserInfo.userName;
    },
    getUserLevel() {
      return this.getUserInfo.userLevel;
    },
    getLevelName() {
      let name = '';
      if (this.getUserLevel) {
        name = this.$t('level.' + this.getUserLevel);
      }
      return name;
    }
  },
  methods: {
    onLogout() {
      localStorage.removeItem('token');
      this.$router.replace('/login');
    },
    switchLang(value) {
      localStorage.setItem('lang', value);
      this.lang = value;
      window.location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  .logo-green {
    img {
      height: 30px;
    }
  }

  .right-dropdown {
    img {
      height: 35px;
      margin-right: 10px;
      border-radius: 5px;
    }

    .name {
      color: #071437;
    }
  }
}

.dropUser {
  padding: 10px 0;
  border-bottom: 0.5px solid var(--border);
  font-weight: bold;

  .level {
    img {
      height: 35px;
      margin-right: 10px;
    }
  }
}

.ant-dropdown-menu {
  padding: 15px;
}

.ant-dropdown-menu-item {
  margin: 7px 0;
  width: 220px;

  &:hover {
    a {
      color: var(--headerDropHover) !important;
    }
  }
}


.ant-dropdown-menu-submenu-arrow {
  display: none !important;
}

.menu-item {
  display: flex;
  width: 196px;
}

.flag {
  width: 25px;
  margin-left: 10px;
  height: 20px;
  border-radius: 3px;
}

.show {
  display: none;
}


@media screen and (max-width: 576px) {
  .header {
    .logo-green {
      img {
        height: 20px;
      }
    }

  }

  .show {
    display: block;
    margin-right: 10px;
  }
}
</style>